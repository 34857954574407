/*------------------------------------*\
    Page Template: Chisiamo
    chisiamo.php
    .page-template-chisiamo
\*------------------------------------*/


.page-template-chisiamo {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .azienda__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: $gutter * 2 $gutter * 3;
        background: url('../images/1963.svg') no-repeat right -145px top 0;
        background-size: 80%;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 300;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 120px;
            margin-top: $gutter * 1.5;

            @media #{$media-b} {
                gap: 60px;
            }

            @media #{$media-sm} {
                margin-top: $gutter;
                flex-direction: column;
                align-items: center;
            }

            @media #{$media-sx} {
                margin-top: $gutter / 2;
            }

            .blocco_images {
                display: flex;
                justify-content: flex-end;
                width: 50%;
                gap: 30px;

                @media #{$media-sm} {
                    width: 100%;
                    justify-content: center;
                }

                &.reversed {
                    justify-content: flex-start;

                    @media #{$media-sm} {
                        justify-content: center;
                    }    
                }

                figure {
                    position: relative;
                }

                #figure1, #figure4 {
                    width: 30%;
                }

                #figure2, #figure3 {
                    width: 50%;
                }

                .mask1 {
                    -webkit-clip-path: url('#chisiamoMask1');
                    clip-path: url('#chisiamoMask1');
                    position: absolute;
                }

                .mask2 {
                    -webkit-clip-path: url('#chisiamoMask2');
                    clip-path: url('#chisiamoMask2');
                    position: absolute;
                }

                .mask3 {
                    -webkit-clip-path: url('#chisiamoMask3');
                    clip-path: url('#chisiamoMask3');
                    position: absolute;
                }

                .mask4 {
                    -webkit-clip-path: url('#chisiamoMask4');
                    clip-path: url('#chisiamoMask4');
                    position: absolute;
                }
            }

        }

        //.col-2

    }

    //.azienda__header

    .azienda__section1 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $gutter * 2;
        padding: $gutter * 5 $gutter * 4;
        background: url('../images/forma_diciassette.svg') no-repeat;
        background-position: left -221px center;
        background-size: 600px;
        background-color: var(--c-theme-7);

        @media #{$media-b} {
            padding: $gutter * 4 $gutter * 3;
        }

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter;
        }

        @media #{$media-sm} {
            padding: $gutter * 2 $gutter;
        }

        @media #{$media-s} {
            padding: $gutter $gutter / 2 $gutter / 2 $gutter / 2;
            margin-top: $gutter / 2;
        }

        .col-1 {
            position: relative;

            .title {
                font-size: 6.3rem;
                font-weight: 500;
                line-height: 1.4;
                color: var(--c-white);
                padding: 0 0 0 $gutter * 4;

                @media #{$media-b} {
                    font-size: 5.2rem;
                    padding-left: $gutter * 3.5;
                }

                @media #{$media-m} {
                    font-size: 4.6rem;
                    padding-left: $gutter;
                }

                @media #{$media-s} {
                    font-size: 4rem;
                    padding-left: 0;
                    display: flex;
                    flex-direction: column;
                }

                @media #{$media-sx} {
                    font-size: 3.6rem;
                }

                span {
                    color: var(--c-black-light);
                }
            }
            .virgolette {
                position: absolute;

                @media #{$media-b} {
                    transform: scale(0.8);
                }

                @media #{$media-m} {
                    transform: scale(0.6);
                    position: relative;
                }

                &.virgolette--uno {
                    left: 0;
                }

                &.virgolette--due {
                    transform: rotate(180deg);
                    margin: $gutter / 2 $gutter;

                    @media #{$media-b} {
                        transform: rotate(180deg) scale(0.8);
                    }

                    @media #{$media-m} {
                        transform: rotate(180deg) scale(0.6);
                        float: right;
                        margin: 0;
                    }
                }
            }
        }
    }

    //.azienda__section1

    .azienda__section2 {
        display: flex;
        flex-direction: column;
        margin-top: $gutter * 2;
        padding: $gutter * 2 $gutter * 4;

        @media #{$media-bx} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-m} {
            margin-top: 0;
            padding: $gutter * 2 $gutter;
        }

        @media #{$media-s} {
            padding: $gutter $gutter / 2;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2 0 $gutter / 2;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $gutter * 3;

            @media #{$media-m} {
                margin-bottom: 0;
            }

            .text {
                font-size: 1.8rem;
                font-weight: 300;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            .col-1 {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 $gutter * 2;

                @media #{$media-bx} {
                    padding-left: $gutter;
                }

                @media #{$media-m} {
                    width: 100%;
                    padding: 0 $gutter;
                }

                @media #{$media-s} {
                    padding: 0 $gutter / 2;
                }

                @media #{$media-sx} {
                    padding: 0;
                }
            }

            //.col-1

            .col-2 {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 0;

                @media #{$media-m} {
                    width: 100%;
                    padding: $gutter 0;

                    figure {
                        max-width: 60%;

                        @media #{$media-s} {
                            max-width: none;
                        }
                    }
                }
            }

            //.col-2

            &.blue {
                background-color: var(--c-corporate-1);

                @media #{$media-m} {
                    margin-bottom: $gutter;
                }

                .col-1 {
                    color: var(--c-white);
                    width: 33%;
                    padding: $gutter;

                    @media #{$media-m} {
                        width: 100%;
                        padding: $gutter;
                    }

                    @media #{$media-sx} {
                        padding: $gutter / 2;
                    }

                    .text {
                        @media #{$media-b} {
                            font-size: 1.6rem;
                        }
                    }
                }

                .col-2 {
                    width: 67%;

                    @media #{$media-m} {
                        width: 100%;
                        padding: 0;
                    }

                    figure {
                        height: 100%;

                        @media #{$media-m} {
                            max-width: 100%;
                        }
                    }
                }
            }

            &.reversed {
                .col-1 {
                    padding: 0;
                    width: 55%;

                    @media #{$media-m} {
                        width: 100%;
                        padding: $gutter 0;
                    }
                }
                .col-2 {
                    width: 45%;
                    padding: $gutter $gutter * 2;

                    @media #{$media-bx} {
                        padding: $gutter 0 0 $gutter;
                    }

                    @media #{$media-m} {
                        width: 100%;
                        order: -1;
                        padding: 0 $gutter;
                    }

                    @media #{$media-s} {
                        padding: 0 $gutter / 2;
                    }

                    @media #{$media-sx} {
                        padding: 0;
                    }
                }
            }
        }
    }

    //.azienda__section2

    .azienda__video {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: $gutter * 6;
        position: relative;

        @media #{$media-m} { 
            margin-top: 0;
        }

        @media #{$media-sx} { 
            margin-top: $gutter;
            margin-bottom: $gutter * 3;
        }

        .col-1 {
            width: 25%;
            height: 52rem;
            display: flex;
            align-items: flex-end;
            color: var(--c-white);
            background-color: var(--c-corporate-1);
            padding: $gutter;

            @media #{$media-b} {
                width: 40%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
                height: auto;
                padding: $gutter $gutter / 2;
            }

            .box {
                display: flex;
                gap: $gutter;
                margin-bottom: $gutter * 2;

                @media #{$media-s} {
                    margin-bottom: 0;
                    gap: $gutter / 4;
                }

                .content {
                    .title {
                        font-size: 4.9rem;
                        line-height: 1.2;

                        @media #{$media-s} {
                            font-size: 3rem;
                        }
                    }

                    p {
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: var(--c-theme-8);
                    }
                }
            }
        }

        //.col-1

        .col-2 {
            width: 75%;
            height: 52rem;
            background-color: var(--c-corporate-1);
            overflow-y: hidden;

            @media #{$media-b} {
                width: 60%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            @media #{$media-sx} {
                height: 25rem;
            }

            .video {
                height: 52rem;

                @media #{$media-sx} {
                    height: 25rem;
                }

                figure {
                    display: inline-flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    &::before {
                        position: absolute;
                        content: url('../images/arrow.svg');
                        width: 120px;
                        height: 80px;
                        background-color: rgba(255,255,255,0.9);
                        border-radius: 10px;
                        cursor: pointer;
                        transition: $trans-default;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        pointer-events: none;
                        line-height: 1;
                    }

                    &:hover::before {
                        background-color: var(--c-accent);
                    }

                    img {
                        height: 100%;
                        object-position: left;
                    }
                }
            }

            .forma {
                position: absolute;

                @media #{$media-sx} {
                    transform: scale(0.8);
                }

                &.forma--uno {
                    bottom: - $gutter * 2.5;
                    left: $gutter * 6;

                    @media #{$media-s} {
                        left: $gutter / 2;
                    }

                    @media #{$media-sx} {
                        left: 0;
                    }
                }

                &.forma--due {
                    bottom: - $gutter * 2.8;
                    left: $gutter * 9;

                    @media #{$media-s} {
                        left: $gutter * 3;
                    }

                    @media #{$media-sx} {
                        left: $gutter * 2;
                    }
                }

                &.forma--tre {
                    top: - $gutter * 10;
                    right: - $gutter * 12;
                }
            }
        }

        //.col-2
    }

    //.azienda__video

}

//.page-template-chisiamo