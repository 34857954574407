/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {

    &::before {
        content: '';
        position: absolute;
        top: 0;
        pointer-events: none;
        width: 100%;
        height: 100vh;
        background: url('../images/home_background.jpg') no-repeat center center / cover;
        background-color: var(--c-corporate-2);
    }

    .header_menu .menu>.menu-item {
        color: var(--c-white);
    }

    .brand {
        svg {
            path {
                fill: var(--c-white);
            }
        }
    }

    .header__content .language__hamburger .icon__nav .icon svg g {
        color: var(--cc-white);
        stroke: var(--c-white);
    }

    .header__content .language__hamburger .language__nav {
        color: var(--c-white);
    }

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .home__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100vh;
        position: relative;
        overflow: hidden;

        @media #{$media-sm} {
            padding-top: 20vh;
            flex-direction: column;
        }

        @media #{$media-sx} {
            padding-top: 15vh;
        }

        .col-1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            color: var(--c-white);
            text-align: right;
            padding: 0 0 0 $gutter;

            @media #{$media-sm} {
                width: 100%;
                padding: 0 $gutter;
                text-align: left;
                align-items: flex-start;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            h1 {
                font-family: $f-family-text;
                font-size: 2.6rem;

                @media #{$media-m} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            p {
                &.title {
                    font-weight: $f-weight-600;
                    text-transform: uppercase;
                    font-size: 12rem;
                    font-family: $f-family-title;
                    line-height: 1;

                    @media #{$media-m} {
                        font-size: 10rem;
                    }

                    @media #{$media-sx} {
                        font-size: 8rem;
                        align-self: center;
                        padding: $gutter / 2 0;
                    }
                }

                &.text {
                    font-family: $f-family-title;
                    font-size: 4rem;
                    text-transform: uppercase;

                    @media #{$media-m} {
                        font-size: 3rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2.4rem;
                    }

                    span {
                        color: var(--c-black-light);
                    }
                }
            }
        }

        //.col-1

        .col-2 {
            .home__animazione_1 {

                position: absolute;
                width: 50vw;
                height: 80vh;
                max-width: 1000px;
                max-height: 1080px;
                top: 25%;
                z-index: -1;

                @media #{$media-sm} {
                    position: relative;
                    width: 100%;
                    height: 40vh;
                    top: 2vh;
                }

                figure {
                    display: block;
                }

                .image {
                    -webkit-clip-path: url('#mask_intro');
                    clip-path: url('#mask_intro');
                    height: auto;
                    width: 100%;

                    @media #{$media-sm} {
                        height: 30vh;
                        width: auto;
                        min-width: 29rem;
                        min-height: 29rem;
                    }
                }

                .shape {
                    width: 100%;
                    height: 100%;

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;

                        clipPath {
                            transform: translate(12%, 12%) scale(0.7);

                            @media #{$media-sm} {
                                transform: translate(10%, 10%) scale(0.8);
                            }

                            @media #{$media-sx} {
                                transform: translate(10%, 2%) scale(0.8);
                            }
                        }

                        ;
                    }
                }
            }
        }

        //.col-2

        .col-3 {
            width: 100%;
            position: absolute;
            bottom: 2rem;
            display: flex;
            justify-content: center;

            @media #{$media-sm} {
                display: none;
            }
        }

        //.col-3

    }

    //.home__header

    .home__section_one {
        display: flex;
        background: url('../images/forma_uno.svg') no-repeat right 20px top -377px;

        @media #{$media-b} {
            background-size: 50%;
            background-position: right 20px top -300px;
        }

        @media #{$media-sx} {
            background-position: right -20px top -120px;
        }

        .col-1 {
            width: 100%;
            padding: $gutter * 2 $gutter * 3;

            @media #{$media-m} {
                padding: $gutter * 2 $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter / 2;
            }

            .text {
                font-size: 3.2rem;
                font-weight: 300;
                max-width: 50%;

                @media #{$media-b} {
                    font-size: 2.2rem;
                }

                @media #{$media-sm} {
                    font-size: 2rem;
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }

                strong {
                    font-weight: 600;
                }
            }

            .title {
                padding-left: $gutter * 2.5;
                margin-top: $gutter * 3;
                position: relative;
                display: flex;
                align-items: center;
                font-size: 4.9rem;

                @media #{$media-b} {
                    font-size: 3.6rem;
                    margin-top: $gutter * 2;
                }

                @media #{$media-s} {
                    font-size: 2.4rem;
                    padding-left: $gutter * 2;
                    margin-top: $gutter;
                }

                span {
                    color: var(--c-accent);
                }

                &::before {
                    width: 60px;
                    height: 60px;
                    background-color: var(--c-accent);

                    @media #{$media-s} {
                        width: 45px;
                        height: 45px;
                    }
                }
            }
        }
    }

    //.home__section_one

    .home__section_two {
        display: flex;
        flex-wrap: wrap;

        .col-1 {
            width: 50%;
            padding: $gutter * 2 $gutter * 3;

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter;
                width: 100%;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .text {
                font-size: 1.8rem;
                font-weight: 300;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

        }

        //.col-1

        .col-2 {
            width: 50%;
            position: relative;
            display: flex;
            align-items: center;

            @media #{$media-sm} {
                width: 100%;
                justify-content: flex-end;
                margin-top: $gutter * 2;
            }

            figure {
                @media #{$media-s} {
                    width: 70%;
                    height: 36rem;
                    overflow: hidden;
                    justify-content: flex-end;
                }
            }

            .forma {
                &.forma--uno {
                    left: - $gutter * 1.5;
                    top: - $gutter * 3;

                    @media #{$media-s} {
                        left: 4rem;
                        top: -19rem;
                        max-width: 80%;
                    }
                }

                &.forma--due {
                    bottom: - $gutter * 2;
                    left: - $gutter * 2;

                    @media #{$media-s} {
                        bottom: -8rem;
                        left: 3rem;
                        max-width: 80%;
                    }
                }
            }
        }

        //.col-2

    }

    //.home__section_two

    .home__section_three {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 5;

        @media #{$media-m} {
            flex-wrap: wrap;
            margin-top: $gutter * 3;
        }

        @media #{$media-sx} {
            margin-top: $gutter * 1.5;
        }

        .col-1 {
            width: 50%;
            position: relative;
            display: flex;
            align-items: center;

            @media #{$media-sm} {
                width: 100%;
                justify-content: flex-start;
                margin-top: $gutter * 2;
            }

            figure {
                @media #{$media-s} {
                    width: 70%;
                    height: 36rem;
                    overflow: hidden;
                    justify-content: flex-end;
                }
            }

            .forma {
                @media #{$media-s} {
                    max-width: 70%;
                }

                &.forma--uno {
                    left: $gutter * 3;
                    top: - $gutter * 2;
                }

                &.forma--due {
                    top: - $gutter * 2;
                    right: $gutter * 4;

                    @media #{$media-m} {
                        top: -5rem;
                        right: auto;
                    }
                }

                &.forma--tre {
                    bottom: - $gutter * 2;
                    right: - $gutter * 1.5;

                    @media #{$media-m} {
                        right: 0;
                    }
                }
            }

        }

        //.col-1

        .col-2 {
            width: 50%;
            padding: $gutter * 2 $gutter * 3;

            @media #{$media-b} {
                padding: $gutter $gutter * 2;
            }

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter;
                width: 100%;
                order: -1;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .text {
                font-size: 1.8rem;
                font-weight: 300;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-2

    }

    //.home__section_three

    .home__section_four {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 5;

        @media #{$media-m} {
            flex-wrap: wrap;
            margin-top: $gutter * 3;
        }

        @media #{$media-sx} {
            margin-top: $gutter * 1.5;
        }

        .col-1 {
            width: 50%;
            padding: $gutter * 2 $gutter * 3;

            @media #{$media-b} {
                padding: $gutter $gutter * 2;
            }

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter;
                width: 100%;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .text {
                font-size: 1.8rem;
                font-weight: 300;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

        }

        //.col-1

        .col-2 {
            width: 50%;
            position: relative;
            display: flex;
            align-items: center;

            @media #{$media-sm} {
                width: 100%;
                justify-content: flex-end;
                margin-top: $gutter * 2;
            }

            figure {
                @media #{$media-s} {
                    width: 70%;
                    height: 36rem;
                    overflow: hidden;
                    justify-content: flex-end;
                }
            }

            .forma {
                @media #{$media-s} {
                    max-width: 65%;
                }

                &.forma--uno {
                    left: - $gutter * 1.5;
                    top: - $gutter * 2;

                    @media #{$media-s} {
                        left: $gutter;
                        top: - $gutter * 4;
                    }
                }

                &.forma--due {
                    bottom: - $gutter * 2;
                    right: 0;

                    @media #{$media-s} {
                        bottom: - $gutter * 4;
                    }
                }
            }
        }

        //.col-2

    }

    //.home__section_four

    .home__section_news {
        padding: $gutter * 2 $gutter * 3;
        margin-top: $gutter * 3;

        @media #{$media-b} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter * 2 $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 737px;
            z-index: -1;
            background: url('../images/forma_nove.svg') no-repeat -40% 0;

            @media #{$media-uw} {
                background-position: 0 0;
            }

            @media #{$media-b} {
                background-position: left -600px top 0;
            }
        }

        >.content {
            .title {
                &::before {
                    width: 60px;
                    height: 60px;
                    background-color: var(--c-accent);
                }
            }
        }

        .items {
            display: flex;
            margin-top: $gutter;
            gap: $gutter;

            @media #{$media-b} {
                flex-wrap: nowrap;
                overflow-x: auto;
            }

            figure {
                height: $gutter * 8;
                overflow: hidden;

                @media #{$media-sm} {
                    height: $gutter * 6;
                }
            }

            .item {
                width: 33%;
                position: relative;

                @media #{$media-b} {
                    width: 50%;
                    flex: 0 0 auto;
                }

                @media #{$media-sm} {
                    width: 75%;
                }

                &:hover {
                    outline: .1rem solid var(--c-accent);
                    outline-offset: 0;
                }

                .link {

                    outline: none;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 5;
                    }

                }// .link

                .content {
                    display: flex;
                    flex-direction: column;

                    .time {
                        align-self: flex-end;
                        font-size: 1.4rem;
                        font-weight: 300;
                        margin-top: $gutter / 3;
                    }

                    h4 {
                        align-self: center;
                        font-size: 2rem;
                        padding: $gutter $gutter / 2 $gutter / 2;

                        @media #{$media-sm} {
                            align-self: flex-start;
                            padding: $gutter / 2 0;
                        }
                    }

                    p {
                        @media #{$media-sm} {
                            font-size: 1.4rem;
                        }
                    }
                }
            }

        }

    }

    //.home__section_news

}

// .page-template-homepage