/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/


.page-template-contatti {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .contatti__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 300;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        //.col-1

    }

    //.contatti__header

    .contatti__recapitivari {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $gutter * 3;

        @media #{$media-b} {
            padding: 0 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: 0 $gutter;
        }

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        .col-1,
        .col-2 {
            .title {
                font-size: 4.2rem;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }
        }

        .col-1 {
            width: 60%;
            padding: 0 $gutter 0 $gutter * 3;

            @media #{$media-bx} {
                padding: 0 $gutter / 2 0 $gutter;
            }

            @media #{$media-m} {
                width: 100%;
                padding-left: 0;
            }

            .contatti_ok {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }

        .col-2 {
            width: 40%;
            padding: 0 0 0 $gutter * 3;

            @media #{$media-bx} {
                padding-left: $gutter * 2;
            }

            @media #{$media-b} {
                padding-left: $gutter;
            }

            @media #{$media-m} {
                padding-left: 0;
                margin: $gutter 0;
                width: 100%;
            }

            .items {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding: $gutter / 2 0 0 $gutter * 1.5;

                @media #{$media-bx} {
                    padding-left: 0;
                }

                @media #{$media-m} {
                    flex-direction: row;
                }

                .item {
                    display: flex;
                    align-items: center;
                    margin-bottom: $gutter / 4;

                    @media #{$media-m} {
                        margin-right: $gutter;
                    }

                    .icon {
                        width: 50px;
                        margin-right: $gutter / 3;
                        display: flex;
                        align-items: center;
                    }

                    a {
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 2.6rem;
                            font-weight: 300;
                            line-height: 1;
                            margin-right: $gutter / 4;
                        }
                    }
                }
            }
        }

        .gform_heading {
            .gform_required_legend {
                display: none;
            }
        }

        .gform_footer {
            display: flex;
            justify-content: center;

            button {
                margin-top: $gutter;
                background-color: var(--c-corporate-1);
                color: var(--c-white);
                text-transform: uppercase;
                padding: $gutter / 3 $gutter * 1.5;

                @media #{$media-m} {
                    margin-top: 0;
                }
            }
        }
    }

    //.contatti__recapitivari

    .contatti__mappa {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
        }

        .forma {
            &.forma--uno {
                right: 0;
                bottom: 0;
            }
        }

        .col-1 {
            width: 100%;

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-accent);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            padding: $gutter / 2 $gutter * 4;

            @media #{$media-bx} {
                padding: $gutter / 2 $gutter * 2;
            }

            @media #{$media-b} {
                padding: $gutter / 2 0;
            }

            @media #{$media-sx} {
                padding: $gutter / 4 0;
            }

            .acf-map {
                height: 430px;
                margin-bottom: $gutter * 4;

                @media #{$media-b} {
                    height: 300px;
                    margin-bottom: 0;
                }
            }

        }

        //.col-2


    }

    //.contatti__mappa
}

//.page-template-contatti