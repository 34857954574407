/*------------------------------------*\
    Page Template: Dettaglio Produzione
    dettaglio-produzione.php
    .page-template-dettaglio-produzione
\*------------------------------------*/


.page-template-dettaglio-produzione {

    .dettaglio_produzione__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-m} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-s} {
                    font-size: 2rem;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }
            }

            .text {
                font-size: 2.4rem;
                font-weight: 300;
                padding-right: $gutter;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }//.col-1

        .col-2 {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$media-m} {
                width: 100%;
            }

            @media #{$media-sm} {
                flex-direction: column;
                align-items: flex-start;
            }

        }//.col-2

    }//.dettaglio_produzione__header

    .dettaglio_produzione__gallery {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 2;
        margin-bottom: $gutter * 4;
        position: relative;

        @media #{$media-sm} {
            margin-bottom: $gutter * 2;
        }

        @media #{$media-sx} {
            margin-bottom: 0;
        }
        
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 150%;
            left: -10%;
            top: -20%;
            background: url('../images/forma_dieci.svg') no-repeat top center;

            @media #{$media-sm} {
                top: -10%;
            }
        }

        .col-1 {
            width: 25%;
            height: 48rem;
            display: flex;
            align-items: flex-end;
            color: var(--c-white);
            background-color: var(--c-corporate-1);
            padding: $gutter;

            @media #{$media-b} {
                width: 40%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
                height: auto;
                padding: $gutter $gutter / 2;
            }

            .box {
                display: flex;
                gap: $gutter;
                margin-bottom: $gutter * 2;

                @media #{$media-s} {
                    margin-bottom: 0;
                    gap: $gutter / 4;
                }

                .content {
                    .title {
                        font-size: 4.9rem;

                        @media #{$media-s} {
                            font-size: 3rem;
                        }
                    }

                    p {
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: var(--c-theme-8);
                    }
                }
            }
        }

        .col-2 {
            width: 75%;
            height: 48rem;
            overflow-y: hidden;

            @media #{$media-b} {
                width: 60%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            .gallery {
                height: 48rem;

                .single__image {
                    height: 48rem;

                    figure,
                    picture,
                    .image-fit {
                        height: 100%;
                    }
                }
            }
        }
    }

    //.dettaglio_produzione__gallery
}

//.page-template-dettaglio-produzione