/*------------------------------------*\
    Page Template: Storia
    storia.php
    .page-template-storia
\*------------------------------------*/


.page-template-storia {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .storia__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 300;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            margin-top: $gutter * 1.5;

            @media #{$media-m} {
                margin-top: $gutter;
            }

            @media #{$media-sx} {
                margin-top: 0;
            }

            .text {
                width: 50%;
                font-size: 2rem;
                font-weight: 300;
                padding-left: $gutter * 3;

                @media #{$media-bx} {
                    padding-left: 0;
                }

                @media #{$media-m} {
                    width: 100%;
                }

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }

            figure {
                width: 50%;
                padding-left: $gutter * 2;
                margin: - $gutter * 4 0 $gutter 0;

                @media #{$media-b} {
                    padding-left: $gutter;
                }

                @media #{$media-m} {
                    width: 60%;
                    margin: 0;
                    margin-bottom: $gutter;
                    padding-left: 0;
                    order: -1;
                }

                @media #{$media-sx} {
                    width: 100%;
                }
            }

        }

        //.col-2

    }

    //.storia__header

    .storia__timeline {
        .timeline {
            position: relative;
            padding-top: $gutter * 4;

            @media #{$media-b} {
                padding-top: $gutter;
            }

            @media #{$media-sx} {
                padding-top: 0;
            }

            &::after {
                position: absolute;
                top: $gutter * 4;
                left: 50%;
                content: '';
                width: 3px;
                height: 94%;
                border: 3px solid var(--c-corporate-3);

                @media #{$media-b} {
                    top: $gutter;
                    height: 100%;
                }

                @media #{$media-s} {
                    display: none;
                }
            }

            .forma {
                &.forma--uno {
                    right: -38rem;
                    top: -30rem;
                }
            }

            .content {
                padding: $gutter / 3 $gutter / 2;
                width: 35%;
                position: relative;
                margin-bottom: $gutter * 3;

                @media #{$media-m} {
                    margin-bottom: $gutter;
                    width: 40%;
                }

                @media #{$media-s} {
                    width: 100%;
                }

                span {
                    font-size: 4.2rem;
                    line-height: 1.2;

                    @media #{$media-b} {
                        font-size: 3.6rem;
                    }

                    @media #{$media-m} {
                        font-size: 3rem;
                    }

                    @media #{$media-sm} {
                        font-size: 2.4rem;
                    }
                }

                p {
                    font-size: 1.8rem;
                    font-weight: 300;

                    @media #{$media-m} {
                        font-size: 1.6rem;
                    }
                }
            }

            .box-timeline-right,
            .box-timeline-left {
                display: flex;
                justify-content: center;
                position: relative;

                @media #{$media-s} {
                    justify-content: flex-start;
                    padding-left: $gutter * 1.5;

                    @media #{$media-s} {
                        padding-left: $gutter / 2;
                    }
                }

                .year {
                    width: 159px;
                    height: 116px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: url('../images/forma_diciotto.svg') no-repeat;
                    font-size: 4.2rem;
                    line-height: 1;
                    color: var(--c-white);
                    z-index: 2;
                    text-align: center;

                    @media #{$media-m} {
                        font-size: 3.6rem;
                        width: 130px;
                        height: 94px;
                    }

                    @media #{$media-sm} {
                        font-size: 3.2rem;
                        width: 100px;
                        height: 72px;
                    }

                    @media #{$media-s} {
                        font-size: 2.6rem;
                        width: 100px;
                        height: 63px;
                    }
                }

                
            }

            .box-timeline-right {
                .content {
                    margin-right: -37%;
                    margin-left: 1%;

                    @media #{$media-m} {
                        margin-right: -41%;
                        margin-left: 1%;
                    }

                    @media #{$media-s} {
                        margin: 0;
                        margin-bottom: $gutter / 2;
                    }
                }
            }

            .box-timeline-left {
                @media #{$media-s} {
                    flex-direction: row-reverse;
                }

                .content {
                    margin-left: -36%;
                    margin-right: 2%;
                    text-align: right;

                    @media #{$media-m} {
                        margin-left: -41%;
                        margin-right: 1%;
                    }

                    @media #{$media-s} {
                        margin: 0;
                        margin-bottom: $gutter / 2;
                        text-align: left;
                    }
                }
            }
        }
    }

    //.storia__timeline

}

//.page-template-storia