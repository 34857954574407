/*------------------------------------*\
    Page Index: Blog
    index.php
    .archive
\*------------------------------------*/

.archive, .single-post {
    .post--archive, .post--single {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;
        position: relative;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        h1.title {
            font-size: 2.4rem;
            font-weight: 500;
            color: var(--c-corporate-4);
            margin-bottom: $gutter / 2;

            @media #{$media-s} {
                font-size: 2rem;
            }
        }

        h2.title {
            font-size: 4.6rem;
            font-weight: 700;
            line-height: 1.4;
            color: var(--c-corporate-4);
            margin-bottom: $gutter;

            @media #{$media-sm} {
                font-size: 3.2rem;
                margin-bottom: $gutter / 4;
            }
        }

        .col-1 {
            width: 100%;
        }

        .blog--list {

            width: 100%;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: $gutter * 10;
                width: 100%;
                height: 737px;
                z-index: -1;
                background: url('../images/forma_nove.svg') no-repeat -40% 0;
    
                @media #{$media-uw} {
                    background-position: 0 0;
                }
    
                @media #{$media-b} {
                    background-position: left -600px top 0;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: $gutter * 7;
                width: 100%;
                height: 50px;
                z-index: -1;
                background: url('../images/linea_news.svg') no-repeat -40% 0;
            }

            .items {
                display: flex;
                flex-wrap: wrap;
                gap: $gutter;

                @media #{$media-b} {
                    gap: $gutter / 2;
                }

                @media #{$media-s} {
                    gap: 0;
                }
    
                figure {
                    height: $gutter * 8;
                    overflow: hidden;
    
                    @media #{$media-sm} {
                        height: $gutter * 6;
                    }
                }
    
                .item {
                    width: 30%;
                    position: relative;
    
                    @media #{$media-b} {
                        width: 48%;
                        flex: 0 0 auto;
                    }

                    @media #{$media-s} {
                        width: 100%;
                        margin-bottom: $gutter;
                    }
    
                    &:hover {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }
    
                    .link {
    
                        outline: none;
    
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }
    
                    }// .link
    
                    .content {
                        display: flex;
                        flex-direction: column;
    
                        .time {
                            align-self: flex-end;
                            font-size: 1.4rem;
                            font-weight: 300;
                            margin-top: $gutter / 3;
                        }
    
                        h4 {
                            align-self: center;
                            font-size: 2rem;
                            padding: $gutter $gutter / 2 $gutter / 2;
    
                            @media #{$media-sm} {
                                align-self: flex-start;
                                padding: $gutter / 2 0;
                            }
                        }
    
                        p {
                            @media #{$media-sm} {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
    
            }
    
        }//.blog--list

        .blog--detail {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 737px;
                z-index: -1;
                background: url('../images/forma_nove.svg') no-repeat 100% 0 / 50%;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: $gutter * 23;
                width: 100%;
                height: 50px;
                z-index: -1;
                background: url('../images/linea_news.svg') no-repeat 300% 0;
                transform: rotate(-20deg);
            }

            .col-1 {
                width: 45%;

                @media #{$media-m} {
                    width: 100%;
                }

                .text {
                    padding: $gutter $gutter 0 0;

                    @media #{$media-m} {
                        padding: $gutter 0 0 0;
                    } 
                    
                    @media #{$media-sx} {
                        font-size: 1.5rem;
                    }  
                }
            }

            .col-2 {
                width: 55%;
                position: relative;

                @media #{$media-m} {
                    width: 100%;
                    order: -1;
                }

                .time {
                    color: var(--c-corporate-2);
                    display: inline-block;
                    margin-bottom: $gutter / 3;
                }

                .item {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media #{$media-s} {
                        height: 400px;
                    }

                    @media #{$media-sx} {
                        height: 320px;
                    }

                    figure, picture {
                        width: 720px;
                        height: 480px;
                        display: flex;
                        align-items: stretch;
    
                        img {
                            width: 100%;
                            height: 100%;
                            max-width: none;
                        }
                    }
                }

                

            }

        }//.blog--detail

    }//.post--archive
}
//.archive