/*------------------------------------*\
    Page Template: Produzione
    produzione.php
    .page-template-produzione
\*------------------------------------*/


.page-template-produzione {

    .produzione__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        h1.title {
            font-size: 2.4rem;
            font-weight: 500;
            color: var(--c-corporate-4);
            margin-bottom: $gutter / 2;

            @media #{$media-s} {
                font-size: 2rem;
            }
        }

        h2.title {
            font-size: 4.6rem;
            font-weight: 700;
            line-height: 1.4;
            color: var(--c-corporate-4);
            margin-bottom: $gutter;

            @media #{$media-sm} {
                font-size: 3.2rem;
                margin-bottom: $gutter / 4;
            }
        }

        .col-1 {
            width: 100%;
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$media-m} {
                flex-direction: column;
            }

            figure {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .mask_prod {
                    -webkit-clip-path: url('#mask_prod');
                    clip-path: url('#mask_prod');
                    position: absolute;
                }
            }

            .text {
                font-size: 2.4rem;
                font-weight: 300;
                max-width: 70%;
                padding-right: $gutter;

                @media #{$media-m} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter;
                    padding-right: 0;
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-2

        .col-3 {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: $gutter;

            @media #{$media-sm} {
                flex-direction: column;
                margin-top: 0;
            }

            .text {
                font-size: 1.8rem;
                line-height: 1.8;
                font-weight: 300;
                max-width: 25%;
                padding-top: $gutter;

                @media #{$media-bx} {
                    max-width: 40%;
                }

                @media #{$media-m} {
                    max-width: 50%;
                }

                @media #{$media-sm} {
                    order: -1;
                    max-width: none;
                    padding: $gutter 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            figure {
                position: relative;
                margin: 0 $gutter * 2;  
                justify-content: flex-end;              

                @media #{$media-m} {
                    margin: 0 $gutter;
                    justify-content: center; 
                }

                @media #{$media-s} {
                    margin: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: - $gutter * 3;
                    width: 100%;
                    height: 100%;
                    background: url('../images/forma_dieci.svg') no-repeat top left / 71%;
                    z-index: -1;

                    @media #{$media-bx} {
                        right: 0;
                    }
                }

                .circle {
                    border-radius: 50%;
                    border: 1px solid transparent;
                    overflow: hidden;
                    width: 78%;
                    height: 98%;

                    img {
                        align-self: center;
                    }
                }
            }
        }

        //.col-2

    }

    //.produzione__header

    .produzione__section1 {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter * 3;
        background: url('../images/forma_undici.svg') no-repeat -700px 0 / contain;

        @media #{$media-m} {
            padding: $gutter $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
        }

        .col-1 {
            width: 100%;

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-accent);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            padding: $gutter / 2 $gutter * 4;
            font-size: 1.8rem;
            font-weight: 300;

            @media #{$media-bx} {
                padding: $gutter / 2 $gutter * 3;
            }

            @media #{$media-b} {
                padding: $gutter / 2 $gutter * 2;
            }

            @media #{$media-m} {
                padding: $gutter / 2 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 4 $gutter / 4;
                font-size: 1.6rem;
            }

            &.icona {
                @media #{$media-b} {
                    padding: $gutter / 4 $gutter / 4;
                }
            }

            .content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: $gutter;
                margin-bottom: $gutter;

                @media #{$media-sm} {
                    margin-bottom: 0;
                }

                .box {
                    width: 50%;
                    padding: $gutter;

                    @media #{$media-bx} {
                        padding: 0;
                    }

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    &.icona {
                        display: flex;
                        
                        @media #{$media-bx} {
                            width: 45%;
                        }

                        @media #{$media-m} {
                            width: 100%;
                        }

                        figure {
                            align-self: start;
                            flex: 0 0 auto;
                            margin-left: - $gutter * 1.5;
                            margin-right: $gutter / 1.5;

                            @media #{$media-m} {
                                margin-left: 0;
                                margin-right: $gutter / 4;
                                min-width: 40px;
                                min-height: 40px;
                                flex: 0 auto;
                            }
                        }

                        p {
                            max-width: 100%;
                        }
                    }

                    .title {
                        font-size: 4.2rem;
                        line-height: 1.2;
                        margin-bottom: $gutter / 2;

                        @media #{$media-bx} {
                            font-size: 3.6rem;
                        }

                        @media #{$media-b} {
                            font-size: 3.2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 2.6rem;
                        }
                    }

                    p {
                        max-width: 90%;
                        font-weight: 300;

                        @media #{$media-bx} {
                            margin-bottom: $gutter;
                        }

                        @media #{$media-s} {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }

        //.col-2

    }

    //.produzione__section1

    .produzione__section2 {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
        }

        .col-1 {
            width: 100%;

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-accent);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            padding: $gutter / 2 $gutter * 4;

            @media #{$media-bx} {
                padding: $gutter / 2 $gutter * 2;
            }

            @media #{$media-b} {
                padding: $gutter / 2 0;
            }

            @media #{$media-sx} {
                padding: $gutter / 4 $gutter / 4;
            }

            .content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                .box {
                    width: 50%;
                    padding: $gutter;
                    display: flex;
                    align-items: center;
                    gap: $gutter / 2;

                    @media #{$media-m} {
                        padding: $gutter / 4;
                        width: 100%;
                    }

                    @media #{$media-s} {
                        padding: 0;
                    }

                    .icon {
                        width: 100px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 0 0 auto;
                        color: var(--c-white);
                        font-size: 4.2rem;
                        font-family: $f-family-title;
                        background: url('../images/forma_dodici.svg') no-repeat center center / contain;

                        @media #{$media-s} {
                            font-size: 3rem;
                            width: 80px;
                            height: 80px;
                        }

                        @media #{$media-sx} {
                            font-size: 2.6rem;
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .text {
                        font-size: 1.8rem;
                        margin-right: $gutter / 2;
                        display: flex;
                        flex-wrap: wrap;

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    a {
                        font-size: 1.6rem;
                        font-weight: 300;
                        text-decoration: underline;
                        padding-right: $gutter;
                        position: relative;
                        display: flex;
                        align-items: center;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            width: 20px;
                            height: 20px;
                            background: url('../images/icona_link.svg') no-repeat;
                        }
                    }
                }
            }

            //.content

            .content__info {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: $gutter * 2;

                @media #{$media-s} {
                    margin-top: $gutter;
                }

                a {
                    margin-top: $gutter / 3;
                }
            }

            //.content__info

        }

        //.col-2


    }

    //.produzione__section2

    .produzione__gallery {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;

        .col-1 {
            width: 25%;
            height: 48rem;
            display: flex;
            align-items: flex-end;
            color: var(--c-white);
            background-color: var(--c-corporate-1);
            padding: $gutter;

            @media #{$media-b} {
                width: 40%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
                height: auto;
                padding: $gutter $gutter / 2;
            }

            .box {
                display: flex;
                gap: $gutter;
                margin-bottom: $gutter * 2;

                @media #{$media-s} {
                    margin-bottom: 0;
                    gap: $gutter / 4;
                }

                .content {
                    .title {
                        font-size: 4.9rem;

                        @media #{$media-s} {
                            font-size: 3rem;
                        }
                    }

                    p {
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: var(--c-theme-8);
                    }
                }
            }
        }

        .col-2 {
            width: 75%;
            height: 48rem;
            overflow-y: hidden;

            @media #{$media-b} {
                width: 60%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            .gallery {
                height: 48rem;

                .single__image {
                    height: 48rem;

                    figure,
                    picture,
                    .image-fit {
                        height: 100%;
                    }
                }
            }
        }
    }

    //.produzione__gallery
}

//.page-template-produzione