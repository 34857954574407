/*------------------------------------*\
    Page Template: Certificazioni
    certificazioni.php
    .page-template-certificazioni
\*------------------------------------*/


.page-template-certificazioni {

    #main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .certificazioni__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;

        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 300;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        //.col-1

    }

    //.certificazioni__header

    .certificazioni__section1 {

        display: flex;
        flex-wrap: wrap;
        padding: 0 $gutter * 3 $gutter * 3 $gutter * 3;

        @media #{$media-m} {
            padding: 0 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        .content {
            display: flex;
            padding-bottom: $gutter * 12;
            gap: $gutter;

            @media #{$media-sm} {
                flex-direction: column;
                padding-bottom: $gutter * 5;
            }

            @media #{$media-s} {
                padding-bottom: $gutter * 3;
            }

            .forma {
                &.forma--uno {
                    right: 0;
                }
            }

            .box-certificazioni {
                width: 50%;
                padding: 0 $gutter * 2;

                @media #{$media-bx} {
                    padding: 0 $gutter;
                }

                @media #{$media-b} {
                    padding: 0;
                }

                .top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: $gutter / 2;
                    min-height: $gutter * 2.5;

                    @media #{$media-m} {
                        min-height: 0;
                        height: $gutter * 2;
                    }
                    
                    img {
                        height: 100%;
                    }

                    h3 {
                        font-size: 4.2rem;

                        @media #{$media-b} {
                            font-size: 3.6rem;
                        }

                        @media #{$media-m} {
                            font-size: 3.2rem;
                        }
                    }
                }

                p {
                    font-size: 1.8rem;
                    font-weight: 300;
                    padding-right: $gutter;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }

                a {
                    font-weight: 300;
                    text-decoration: underline;

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }
                }
            }
        }

    }

    //.certificazioni__section1

}

//.page-template-certificazioni