/*------------------------------------*\
    Page Template: Settori
    settori.php
    .page-template-settori
\*------------------------------------*/


.page-template-settori {

    .settori__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: $gutter * 2 0;

        @media #{$media-m} {
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter 0;
        }

        @media #{$media-sx} {
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;
            padding: 0 $gutter * 3;

            @media #{$media-bx} {
                padding: 0 $gutter * 2;
            }

            @media #{$media-b} {
                width: 100%;
            }

            @media #{$media-sm} {
                padding: 0 $gutter;
            }

            @media #{$media-s} {
                width: 100%;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 300;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            align-items: flex-end;

            @media #{$media-b} {
                margin-top: $gutter * 1.5;
            }

            @media #{$media-sm} {
                margin-top: $gutter;
                flex-direction: column;
                align-items: flex-start;
            }

            @media #{$media-sx} {
                margin-top: $gutter / 2;
            }

            .content {
                width: 50%;
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: var(--c-theme-8);
                background-color: var(--c-corporate-1);
                padding: 0 $gutter * 3 0 $gutter * 5;

                @media #{$media-bx} {
                    padding-left: $gutter * 2;
                }

                @media #{$media-b} {
                    height: 100%;
                    padding: 0 $gutter * 2;
                }

                @media #{$media-m} {
                    padding-right: $gutter / 2;
                }

                @media #{$media-sm} {
                    width: 100%;
                    padding: $gutter;
                }

                .title {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;

                    @media #{$media-m} {
                        font-size: 2.6rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2.2rem;
                    }
                }

                .text {
                    font-size: 1.8rem;
                    font-weight: 300;

                    @media #{$media-m} {
                        font-size: 1.6rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }
                }
            }

            figure {
                width: 50%;
                margin-top: - $gutter * 3;

                @media #{$media-b} {
                    margin-top: 0;
                }

                @media #{$media-sm} {
                    width: 100%;
                }
            }

        }

        //.col-2

    }

    //.settori__header

    .settori__section1 {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter * 3 0 $gutter * 3;
        background: url('../images/forma_undici.svg') no-repeat right -700px bottom -400px / contain;

        @media #{$media-bx} {
            padding: 0 $gutter * 2;
        }

        @media #{$media-m} {
            padding: $gutter / 2;
            background-size: 80%;
            background-position: right -500px bottom -250px;
        }

        @media #{$media-sx} {
            padding: $gutter / 2 0;
        }

        .col-1 {
            width: 100%;

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-theme-7);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }

        }

        //.col-1

        .col-2 {
            width: 100%;
            margin-top: $gutter * 3;

            @media #{$media-m} {
                margin-top: $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
                margin-top: $gutter / 2;
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: transparent;
                background-size: 100%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }

            .content {
                display: flex;
                flex-wrap: wrap;

                @media #{$media-m} {
                    justify-content: center;
                }

                .title {
                    font-size: 4.2rem;
                    position: relative;

                    @media #{$media-b} { 
                        font-size: 3.6rem;
                    }

                    @media #{$media-sx} { 
                        font-size: 2.8rem;
                    }
                }

                .text {
                    font-size: 1.8rem;
                    font-weight: 300;

                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }

                .col-1 {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    text-align: right;

                    @media #{$media-m} {
                        width: 50%;
                    }

                    @media #{$media-s} {
                        width: 100%;
                        align-items: flex-start;
                        text-align: left;
                        margin-bottom: $gutter;
                    }

                    @media #{$media-sx} {
                        padding: 0;
                    }

                    .text {
                        padding: 0 $gutter * 2.5;

                        @media #{$media-bx} {
                            padding-left: 0;
                            padding-right: $gutter * 2.2;
                        }
                
                        @media #{$media-b} { 
                            padding-right: $gutter * 2;
                        }

                        @media #{$media-s} { 
                            padding-left: $gutter * 2;
                            padding-right: 0;
                        }
                
                        @media #{$media-sx} { 
                            padding-left: $gutter * 1.7;
                        }
                    }
                }

                //.col-1

                .col-2 {
                    width: 33.33%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 0;

                    @media #{$media-bx} {
                        padding: 0 $gutter / 2;
                    }

                    @media #{$media-m} {
                        width: 70%;
                        order: -1;
                        margin-bottom: $gutter;
                    }

                    @media #{$media-sx} {
                        width: 100%;
                        padding: 0;
                    }
                }

                //.col-2

                .col-3 {
                    width: 33.33%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media #{$media-m} {
                        width: 50%;
                    }

                    @media #{$media-s} {
                        width: 100%;
                    }

                    .text {
                        padding: 0 $gutter * 2.5;

                        @media #{$media-bx} {
                            padding-right: 0;
                            padding-left: $gutter * 2.2;
                        }
                
                        @media #{$media-b} { 
                            padding-left: $gutter * 2;
                        }
                
                        @media #{$media-sx} { 
                            padding-left: $gutter * 1.7;
                        }
                    }
                }

                //.col-3

                .col-4 {
                    width: 100%;
                    padding: $gutter * 2 $gutter * 3 $gutter * 12 $gutter * 2;

                    @media #{$media-m} { 
                        padding: $gutter * 2;
                    }

                    @media #{$media-s} { 
                        padding: $gutter $gutter / 2;
                    }

                    @media #{$media-sx} { 
                        padding: $gutter 0;
                    }
                }

                //.col-4
            }
        }

        //.col-2

    }

    //.settori__section1

    .settori__gallery {
        display: flex;
        flex-wrap: wrap;
        margin-top: - $gutter * 4;
        margin-bottom: $gutter * 2;
        position: relative;

        @media #{$media-m} { 
            margin-top: 0;
        }

        @media #{$media-sx} { 
            margin-bottom: $gutter * 3;
        }

        .col-1 {
            width: 100%;
            padding: 0 $gutter * 3 $gutter * 2 $gutter * 3;

            @media #{$media-m} {
                padding: $gutter $gutter * 2;
            }

            @media #{$media-sm} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2;
            }

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-accent);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 25%;
            height: 48rem;
            display: flex;
            align-items: flex-end;
            color: var(--c-white);
            background-color: var(--c-corporate-1);
            padding: $gutter;

            @media #{$media-b} {
                width: 40%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
                height: auto;
                padding: $gutter $gutter / 2;
            }

            .box {
                display: flex;
                gap: $gutter;
                margin-bottom: $gutter * 2;

                @media #{$media-s} {
                    margin-bottom: 0;
                    gap: $gutter / 4;
                }

                .content {
                    .title {
                        font-size: 4.9rem;

                        @media #{$media-s} {
                            font-size: 3rem;
                        }
                    }

                    p {
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: var(--c-theme-8);
                    }
                }
            }
        }

        //.col-2

        .col-3 {
            width: 75%;
            height: 48rem;
            overflow-y: hidden;

            @media #{$media-b} {
                width: 60%;
            }

            @media #{$media-m} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            .gallery {
                height: 48rem;

                .single__image {
                    height: 48rem;

                    figure,
                    picture,
                    .image-fit {
                        height: 100%;
                    }
                }
            }

            .forma {
                position: absolute;

                @media #{$media-sx} {
                    transform: scale(0.8);
                }

                &.forma--uno {
                    bottom: - $gutter * 2.5;
                    left: $gutter * 6;

                    @media #{$media-s} {
                        left: $gutter / 2;
                    }

                    @media #{$media-sx} {
                        left: 0;
                    }
                }

                &.forma--due {
                    bottom: - $gutter * 2.8;
                    left: $gutter * 9;

                    @media #{$media-s} {
                        left: $gutter * 3;
                    }

                    @media #{$media-sx} {
                        left: $gutter * 2;
                    }
                }
            }
        }

        //.col-3
    }

    //.settori__gallery
}

//.page-template-settori