/*------------------------------------*\
    Page Template: Neutro
    neutro.php
    .page-template-neutro
\*------------------------------------*/


.page-template-neutro {

    .neutro__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 50%;

            @media #{$media-m} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-s} {
                    font-size: 2rem;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }
            }

            .text {
                font-size: 2.4rem;
                font-weight: 300;
                padding-right: $gutter;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }//.col-1

        .col-2 {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$media-m} {
                width: 100%;
            }

            @media #{$media-sm} {
                flex-direction: column;
                align-items: flex-start;
            }

        }//.col-2

        .col-3 {
            width: 50%;
            margin-top: $gutter * 2;

            @media #{$media-m} {
                width: 100%;
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }
            }

            .text {
                font-size: 2.4rem;
                font-weight: 300;
                padding-right: $gutter;

                @media #{$media-sm} {
                    font-size: 1.8rem;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }//.col-3

    }//.neutro__header

}

//.page-template-neutro