/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    background-color: transparent;
    width: 100%;
    height: 15vh;
    max-height: $gutter * 4;
    transition: $trans-default;
    font-family: $f-family-title;

    &.sticky {
        background-color: #091A26;
        height: 10vh;

        .header__content {
            .brand {
                height: 6rem;

                svg path {
                    fill: var(--c-white);
                }
            }
            .language__hamburger {
                display: flex;
                align-items: center;
                justify-content: center;

                .language__nav {
                    margin-right: $gutter;
                    color: var(--c-white);
                }
            }

            .language__hamburger .icon__nav .icon svg g {
                color: var(--c-white);
                stroke: var(--c-white);
            }

            .header_menu .menu>.menu-item {
                color: var(--c-white);
            }

            .header_menu .menu>.menu-item:focus-within, .header_menu .menu>.menu-item:hover {
                color: var(--c-accent);
            }
        }
    }

    @media #{$media-sx} { 
        height: 12vh;
    }

}

// .header










/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 $gutter;

    @media #{$media-s} {
        padding: 0 $gutter / 3;
        justify-content: center;
    }

    .brand {

        width: 20rem;
        height: 8rem;
        z-index: 999999;

        @media #{$media-s} { 
            width: 15rem; 
            height: 6rem;
        }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }

    // .brand


    .language__hamburger {

        @media #{$media-s} {
            position: fixed;
            bottom: $gutter / 3;
            left: 0;
            width: 100%;
        }

        .language__nav {

            font-family: $f-family-title;
            font-size: 1.8rem;
            z-index: 999999;
            position: relative;
            color: var(--c-corporate-2);

            @media #{$media-s} {
                position: fixed;
                top: $gutter / 2;
                right: $gutter / 2;
            }

            a {
                padding: $gutter / 5;
                transition: $trans-default;

                @media #{$media-s} {
                    display: none;
                }

                &:hover,
                &:focus-within {
                    color: var(--c-accent);
                }

                &.selected {
                    @media #{$media-s} {
                        display: flex;
                    }
                }
            }

        }

        // .language__nav

        .icon__nav {

            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media #{$media-s} {
                justify-content: center;
            }

            .item {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: auto;
                height: 8rem;
                position: relative;

            }

            // .item

            .icon {

                border: none;
                padding: 0;
                background-color: transparent;
                fill: currentColor;
                position: relative;

                width: 4rem;
                height: 4.6rem;

                @media #{$media-s} {
                   width: 8rem;
                   height: 6rem;
                }

                &:hover,
                &:focus-within {
                    color: var(--c-accent);
                }

                .mobile {
                    display: none;

                    @media #{$media-s} {
                        display: block;
                    }
                }

                .desktop {
                    @media #{$media-s} {
                        display: none;
                    }
                }

                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: currentColor;

                    g {
                        color: var(--c-corporate-2);
                        stroke: var(--c-corporate-2);

                        @media #{$media-s} {
                            color: var(--c-white);
                            stroke: var(--c-white);
                        }

                        &#menù_open {
                            stroke-width: 0.7px;
                        }
                    }
                }

            }

            // .item

        }

        // .icon__nav
        .language__nav+.icon__nav {
            margin-left: inherit;
        }

        .top_arrow {
            position: fixed;
            bottom: 2%;
            right: $gutter * 2;
            display: none;

            a {
                display: flex;
                width: 40px;
                height: 40px;
                transform: rotate(180deg);
                background: var(--c-black-light);
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 0 1rem;

                svg {
                    margin-bottom: -3px;

                    path {
                        fill: var(--c-white);
                    }
                }
            }
        }

        // .top_arrow

    }


}

// .header__content


.main__nav-opened {
    .header.sticky .header__content .language__hamburger {
        justify-content: flex-end;

        .language__nav  {
            margin-right: 0;
            color: var(--c-white);
        }
    }
    .header .header__content .language__hamburger .language__nav {
        color: var(--c-white);
    }
    .header.sticky .header__content .language__hamburger .icon__nav {
        display: none;
    }
}










/*------------------------------------*\
    Header Menu -> header_menu
    Header Navigation
    nav.header_menu
\*------------------------------------*/

.header_menu {

    @media #{$media-sm} {
        display: none;
    }

    .menu {

        display: flex;
        position: relative;
        gap: $gutter;

        &>.menu-item {

            position: relative;
            color: var(--c-corporate-2);
            transition: $trans-default;

            &>a {

                font-size: 1.8rem;
                line-height: 1;
                padding: 2rem .5rem;
                display: inline-flex;
                align-items: center;
                text-transform: uppercase;
                outline-offset: 0;
            }

            &:hover,
            &:focus-within {
                color: var(--c-accent);
            }

            &.current-menu-item>a,
            &.current-menu-parent>a,
            &.current-menu-ancestor>a {
                // color: var(--c-corporate-1);
            }

        }

        // .menu-item

        .sub-menu {

            // display: none;
            position: absolute;
            top: 100%;
            left: 0;
            // display: none;
            opacity: 0;
            transition: opacity 0.5s ease;

            color: var(--c-white);
            background-color: var(--c-corporate-2);

            min-width: 16rem;
            padding: 2rem;

            .menu-item {

                a {

                    &:hover,
                    &:focus-within {
                        color: var(--c-accent);
                    }

                }

            }

        }

        // .sub-menu

        &>.menu-item-has-children>a {

            align-items: center;
            position: relative;

            &::after {

                display: inline-flex;
                margin-top: .3rem;
                margin-left: .2rem;
                content: '';
                width: 0;
                height: 0;

                border-left: .3rem solid transparent;
                border-right: .3rem solid transparent;
                border-top: .5rem solid currentColor;
                border-bottom: initial;

            }

        }

        .menu-item-has-children:hover>.sub-menu,
        .menu-item-has-children.open>.sub-menu {
            display: block;
            opacity: 1;
            height: auto;
            pointer-events: all;
            z-index: 99999;

        }

        .menu-item-has-children:hover>a ::after,
        .menu-item-has-children.open>a::after {
            border-top: initial;
            border-bottom: .5rem solid currentColor;
        }

        .menu-item-has-children:hover>a ::before,
        .menu-item-has-children.open>a::before {

            display: block;
            content: '';
            width: .2rem;
            height: 1rem;
            background-color: var(--c-corporate-1);

            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

        }

    }

    // .menu

}

// .header_menu










/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {

    color: var(--c-white);
    position: fixed;
    z-index: 99999;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;

    will-change: transform, opacity, visibility;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    @media #{$media-s} {
        width: 100%;
        height: 85%;
        top: auto;
        bottom: 0;
        transform: translateY(100%);
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../images/background_menu.svg') no-repeat top left / cover;

        @media #{$media-s} {
            background: url('../images/background_menu_mobile.svg') no-repeat top center / cover;
        }
    }

    :focus {
        outline: .1rem dashed var(--c-accent);
        outline-offset: .2rem;
    }

    .menu-item-has-children a:focus {   
        color: var(--c-corporate-3) !important;

        .arrowLine {
            transform: rotate(180deg);
            flex: 2;
            padding-left: 0;
            margin-right: $gutter;

            &::before {
                width: 95% !important;

                @media #{$media-b} {
                    width: 85% !important;
                }
            }
        }
    }

    .menu-item-has-children.open {
        .arrowLine {
            transform: rotate(180deg);
            flex: 2;
            padding-left: 0;
            margin-right: $gutter;

            &::before {
                width: 95% !important;

                @media #{$media-b} {
                    width: 85% !important;
                }
            }
        }
    }

    ul.menu:focus {
        outline: none;
    }

    .content {

        padding: $gutter;
        padding-left: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$media-s} {
            padding: 0;
        }

    }

    // .content



    .brand {

        width: 20rem;
        height: 7rem;
        color: var(--c-white);
        fill: currentColor;

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: currentColor;
        }

    }

    // .brand



    .nav_close {

        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        margin-top: $gutter * 1.2;
        z-index: 5;

        border: none;
        padding: 0;
        color: var(--c-white);
        background-color: transparent;
        fill: currentColor;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media #{$media-s} {
            position: fixed;
            bottom: $gutter / 2;
            margin-top: 0;
        }

        &:hover,
        &:focus-within {

            color: var(--c-accent);

        }

        .icon {

            display: block;
            width: 3.5rem;
            height: 4.3rem;

            &.desktop {
                @media #{$media-s} {
                    display: none;
                }
            }

            &.mobile {
                display: none;
                width: 8rem;
                height: 6rem;
                @media #{$media-s} {
                    display: block;
                    width: 6rem;
                    height: 4rem;
                }
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: currentColor;
            }

        }

        // .icon

        .label {

            font-family: $f-family-text;
            margin-top: 1rem;
            display: block;
            font-size: 1.4rem;
            line-height: 1;
            text-shadow: .1rem .1rem .1rem rgba(0, 0, 0, .25);

            @media #{$media-m} {
                font-size: 1.6rem;
            }

        }

        // .label

    }

    // nav_close



    /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/

    .main_menu {

        position: relative;
        z-index: 2;

        max-width: 160rem;
        padding: $gutter;
        margin: auto;
        margin-left: 20%;

        @media #{$media-b} {
            margin-left: 15%;
        }

        @media #{$media-s} {
            padding-top: $gutter * 4;
            margin-left: 0;
        }

        @media #{$media-sx} {
            padding: $gutter * 2.5 $gutter / 2;
        }

        .menu {

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: $gutter / 2 $gutter * 2;

            @media #{$media-bx} {
                gap: $gutter / 3 $gutter * 2;
            }

            @media #{$media-sm} {
                flex-direction: column;
                gap: 0;
            }

            &>.menu-item {

                display: flex;
                justify-content: space-between;
                gap: $gutter / 2;
                height: $gutter * 1.5;

                @media #{$media-b} {
                    height: $gutter * 1.2;
                }

                @media #{$media-m} {
                    flex-direction: column;
                    height: auto;
                    gap: $gutter / 4;
                }

                &>a {

                    display: inline-flex;
                    align-items: center;

                    font-size: 4.9rem;
                    line-height: 1;
                    text-transform: uppercase;
                    color: var(--c-white);
                    transition: $trans-default;

                    &:hover {
                        color: var(--c-corporate-3);
                    }

                    &:focus {
                        color: var(--c-corporate-3);
                    }

                    @media #{$media-bx} {
                        font-size: 4rem;
                        width: 60%;
                    }

                    @media #{$media-m} {
                        width: 100%;
                    }
                    
                    @media #{$media-s} {
                        font-size: 2.6rem;
                    }

                    @media #{$media-sxx} {
                        font-size: 2.4rem;
                    }

                    .arrowLine {
                        color: var(--c-corporate-3);
                        font-size: 3rem;
                        margin-left: $gutter / 2;
                        transition: $trans-default;
                        position: relative;
                        display: flex;
                        align-items: center;

                        &::before {
                            content: '';
                            position: absolute;
                            height: 1px;
                            width: 0%;
                            right: 0;
                            border-bottom: 1px dashed var(--c-corporate-3);
                        }
                    }

                }

            }

            &>.menu-item.open {
                a {
                    color: var(--c-corporate-3);
                }
            }

            // & > .menu-item

            .sub-menu {

                opacity: 0;
                height: 0;
                pointer-events: none;
                margin-top: 0;
                width: 50%;

                @media #{$media-sm} {
                    width: 100%;
                }

                .menu-item {

                    &:not(:first-of-type) {
                        margin-top: 1rem;
                    }

                    a {

                        font-size: 2.4rem;
                        line-height: 1;
                        color: var(--c-corporate-5);
                        margin-top: 0;
                        margin-bottom: $gutter / 2;
                        display: block;
                        font-style: normal;

                        @media #{$media-b} {
                            font-size: 2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                            margin-bottom: 0;
                        }

                        &:hover {
                            color: var(--c-corporate-3);
                        }

                        &:focus {
                            color: var(--c-corporate-3);
                        }
                    }

                }

            }

            // .sub-menu

            .menu-item-has-children>a {
                flex: 1;

                @media #{$media-sx} {
                    flex: none;
                }
            }

            .menu-item-has-children.open>.sub-menu {
                display: block;
                pointer-events: all;
                margin-top: $gutter / 2;
                opacity: 1;
                height: auto;
                pointer-events: all;
                z-index: 99999;

                @media #{$media-sx} {
                    margin-top: 0;
                    margin-bottom: $gutter / 4;
                }
            }

            .menu-item-has-children.open>a::after {
                margin-top: .2rem;
                border-top: initial;
                border-bottom: .5rem solid currentColor;
            }

            .menu-item-has-children:focus-within>.sub-menu {
                opacity: 1;
                height: auto;
                pointer-events: all;
                z-index: 99999;
            }

        }

        // .menu

        .menu li:last-child a {
            color: var(--c-corporate-3);
            font-style: italic;
            font-size: 3.6rem;

            @media #{$media-bx} {
                font-size: 3rem;
            }

            @media #{$media-m} {
                margin-top: $gutter / 2;
            }

            @media #{$media-s} {
                font-size: 2.4rem;
                margin-bottom: $gutter / 6 !important;
            }

            @media #{$media-sx} {
                font-size: 2rem;
            }
        }

        .lang_container {
            margin-top: $gutter / 2;

            .language__nav {
                display: flex;
                gap: 10px;
                font-size: 1.8rem;

                a {
                    &.selected {
                        color: var(--c-accent);
                    }
                }
            }
        }

    }

    // .main_menu

    &.open,
    &.toggled-on {

        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

        @media #{$media-s} {
            transform: translateY(0%);
        }

    }

}

// .main__nav