/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {

    margin-top: $gutter * 4;
    font-family: $f-family-title;

    position: relative;
    z-index: 1;

    @media #{$media-s} { margin-top: $gutter*2; }
    @media #{$media-sx} { margin-top: $gutter; }

    section {
        @media #{$media-uw} {
            width: $w-media-uw;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .footer__social_cta {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid var(--c-corporate-3);
        border-bottom: 1px solid var(--c-corporate-3);
        padding: $gutter / 2 0;

        @media #{$media-b} {
            flex-direction: column;
            align-items: center;
        }

        .social {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $gutter;
            padding: $gutter 0;
            border-right: 1px solid var(--c-corporate-3);

            @media #{$media-b} {
                width: 100%;
                padding: $gutter / 2 0;
            }

            .title {
                font-size: 3rem;
            }

            .items {
                display: flex;
                gap: $gutter;

                @media #{$media-sx} {
                    gap: $gutter / 2;
                }

                .link {
                    width: 6rem;
                    height: 6rem;

                    svg {
                        width: 100%;
                        height: 100%;
                        display: block;
                        path {
                            transition: $trans-default;
                        }
                        &:hover {
                            path {
                                fill: var(--c-accent);
                            }
                        }
                    }
                }
            }


        }//.social

        .cta {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $gutter;
            padding: 0 $gutter 0 $gutter * 3;

            @media #{$media-bx} {
                padding: 0 $gutter 0 $gutter;
            }

            @media #{$media-b} {
                width: 70%;
                padding-bottom: $gutter / 2;
            }

            @media #{$media-m} {
                width: 80%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            @media #{$media-sm} {
                padding: $gutter / 2;
                gap: $gutter / 2;
            }

            .icon { 
                width: 38rem;

                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .title {
                font-size: 4.2rem;

                @media #{$media-sm} {
                    font-size: 3.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }

                @media #{$media-sxx} {
                    font-size: 2rem;
                }
            }

            .text {
                font-size: 1.4rem;
                font-family: $f-family-text;

                @media #{$media-sx} {
                    font-size: 1.2rem;
                }
            }
        }
    }//.footer__social_cta

    .footer__location {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter;

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
        }

        .logo {
            width: 15%;
            border-right: 1px solid var(--c-corporate-3);
            padding-right: $gutter;

            @media #{$media-bx} {
                width: 20%;
            }

            @media #{$media-b} {
                width: 25%;
            }

            @media #{$media-m} {
                width: 40%;
            }

            @media #{$media-s} {
                width: 100%;
                border-right: 0;
                border-bottom: 1px solid var(--c-corporate-3);
                padding-right: 0;
                padding-bottom: $gutter / 2;
                margin-bottom: $gutter / 2;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .brand_footer {
                width: 21rem;

                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .text {
                font-size: 1.2rem;
                font-weight: 300;
                margin-top: $gutter;
            }
            
        }//.logo

        .location {
            width: 85%;
            display: flex;
            align-items: center;

            @media #{$media-bx} {
                width: 80%;
            }

            @media #{$media-b} {
                width: 75%;
            }

            @media #{$media-m} {
                width: 60%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            .items {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                width: 100%;

                @media #{$media-b} {
                    padding: 0 $gutter / 2;
                    gap: $gutter;
                    justify-content: flex-start;
                }

                @media #{$media-s} {
                    padding: 0;
                    gap: $gutter / 4;
                }

                .item {
                    display: flex;
                    align-items: center;
                    font-size: 1.8rem;

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }

                    .icon {
                        height: 6rem;
                        width: 6rem;
                        margin-right: $gutter / 2;
                        flex: 0 0 auto;

                        @media #{$media-sx} {
                            width: 4rem;
                            height: 4rem;
                        }

                        svg {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }
        }//.location

    }//.footer__location


    .footer__policies {
        display: flex;
        flex-wrap: wrap;

        color: var(--c-white);
        background-color: var(--c-corporate-1);

        section {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: $gutter;

            @media #{$media-sx} {
                padding: $gutter / 2;
            }
            
            .copy, .items {
                width: 50%;

                @media #{$media-m} {
                    width: 100%;
                    font-size: 1.4rem;
                }
            }

            .items {
                display: flex;
                justify-content: flex-end;
                text-transform: uppercase;

                @media #{$media-b} {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: $gutter / 4;
                }

                @media #{$media-m} {
                    order: -1;
                    justify-content: center;
                    margin-bottom: $gutter / 2;
                }

                @media #{$media-sx} {
                    flex-direction: column;
                    align-items: center;
                    gap: 0;
                }

                .item {
                    border-right: 1px solid var(--c-white);
                    padding: 0 $gutter / 2;

                    @media #{$media-sx} {
                        border-right: 0;
                        border-bottom: 1px solid var(--c-white);
                        padding: $gutter / 4;
                    }
                }
            }

            .item:last-child {
                border-right: 0;
            }
        }

    }//.footer__policies


}// .footer
