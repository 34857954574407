@media screen and (prefers-reduced-motion: no-preference) {

	html,
	body {
		scroll-behavior: smooth;
	}

}

html {
	font-size: 62.5%;

    &.main__nav-opened { 
        overflow: hidden;
    }
}
// fix margin wpadminbar
html[lang] { margin-top: 0 !important; }

	body {

		color: var(--c-fg);
		background-color: var(--c-bg);
        font-family: var(--f-text);
		font-weight: var(--f-400);
		font-size: 1.6rem;
		//line-height: 2;
		line-height: 1.6;
        -webkit-text-size-adjust: 100%;

		&.main__nav-opened {
			height: 100%;

			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}

			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */

			 // position: fixed;

		}// &.main__nav-opened

	}
	@-moz-document url-prefix() {
    	html.main__nav-opened,body.main__nav-opened{ overflow: hidden; }
	}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $f-family-title;
		}

		p {}

		strong {
			font-weight: $f-weight-600;
		}

		a {
			text-decoration: none;
			color: currentColor;

			&:hover {}

		}










/*------------------------------------*\
	General style here
\*------------------------------------*/

::selection {
	background-color: var(--c-accent);
	color: var(--c-corporate-1);
}

:focus {
	outline: .1rem solid var(--c-accent);
	outline-offset: .2rem;
}

.none { display: none; }

.hidden { visibility: hidden; }

.uppercase { text-transform: uppercase; }

.anchor {
    display: block;
    position: relative;
    top: calc(var(--h-header) * -1);
    visibility: hidden;
}

// Screen reader only
.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.image-auto {
	width: auto;
	height: auto;
}

.image-fit {
	width: 100%;
	height: auto;
    max-width: none;
    object-fit: cover;
}

.title {
    font-family: $f-family-title;
}

.text {
    font-family: $f-family-text;
}

figure, picture {
    display: flex;
}









/*------------------------------------*\
    Text
    .text
\*------------------------------------*/

.text {

    a {
        color: currentColor;
    }

    ul,
    ol {
        margin: 2rem;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        list-style-type: disc;
    }

    p+p {
        margin-top: $gutter * .5;
    }

    strong {
        font-weight: $f-weight-600;
    }

    em {
        font-style: italic;
    }

}// .text










/*------------------------------------*\
    Back link
    .back
\*------------------------------------*/

.back {

    padding: $gutter;
    text-align: center;

    .link {

        display: inline-flex;
        align-items: center;
        position: relative;
        height: 4.5rem;
        padding-left: $gutter*.5;
        padding-right: $gutter*2;

        font-family: $f-family-title;
        font-weight: $f-weight-600;
        font-size: 1.8rem;
		line-height: 1;
        text-transform: uppercase;
        color: var(--c-white);
        background-color: var(--c-corporate-1);

        @media #{$media-sx} {
            font-size: 1.6rem;
            padding-right: $gutter * 1.5;
        }

        &::after {

            display: inline-flex;
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: 0;
            border-left: 4.5rem solid transparent;
            border-right: initial;
            border-top: initial;
            border-bottom: 4.5rem solid var(--c-white);

        }

        &:hover,
        &:focus-within {
            color: var(--c-corporate-1);
            background-color: var(--c-corporate-5);
        }

    }// .link

}// .back
