/*------------------------------------*\
    Post Type: Articoli ( post )
    General
\*------------------------------------*/










/*------------------------------------*\
    Post Type: Articoli ( post )
    index.php
\*------------------------------------*/

.blog,
.archive.date,
.archive.category, {



    .post--archive {

        .box--title {}// .box--title


        .category_menu {

            padding: $gutter;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 2rem;

            .title {

                font-family: $f-family-title;
                font-weight: $f-weight-600;
                font-size: 1.6rem;
                text-transform: uppercase;
                line-height: 1.2;

            }// .title

            .menu {

                display: flex;
                gap: 2rem 1rem;
                flex-wrap: wrap;

            }// .menu

            .menu-item {

                a {

                    display: block;
                    font-family: $f-family-title;
                    font-weight: $f-weight-600;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    padding: 1rem;
                    background-color: var(--c-corporate-3);

                    &:hover,
                    &:focus-within {
                        color: var(--c-white);
                        background-color: var(--c-corporate-1);
                    }

                }// a

                &.current-menu-item a {
                    color: var(--c-corporate-1);
                    background-color: var(--c-accent);
                }

            }// .menu-item

        }// .category_menu


        .post--list {

            padding: $gutter;

            .items {

                display: grid;
                grid-gap: $gutter*2 $gutter*3;
                grid-template-columns: repeat(3, 1fr);

                @media #{$media-b} { grid-gap: $gutter*2; }
                @media #{$media-m} { grid-template-columns: repeat(2, 1fr); }
                @media #{$media-sm} { grid-gap: $gutter; }
                @media #{$media-sx} { grid-template-columns: repeat(1, 1fr); }

                .item {

                    max-width: 50rem;
                    position: relative;

                    &:hover,
                    &:focus-within {
                        outline: .1rem solid var(--c-accent);
                        outline-offset: 0;
                    }

                    .link {

                        outline: none;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }

                    }// .link

                    .image {

                        height: 30rem;
                        border: .1rem solid var(--c-grey-light);

                        .img {

                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;

                        }// .img

                    }// .image

                    .content {

                        padding: 2rem;

                    }// .content

                    .time {

                        font-family: $f-family-title;
                        font-weight: $f-weight-400;
                        font-size: 1.4rem;
                        text-transform: uppercase;

                    }// .time

                    .title {

                        margin-top: 1rem;

                        font-size: 2.4rem;
                        line-height: 1.1;
                        font-weight: $f-weight-600;

                    }// .title

                    .text {

                        margin-top: 1rem;
                        font-size: 1.4rem;
                        line-height: 1.2;

                    }// .text

                }// .item

            }// .items

            & > .title {

                font-size: 2.4rem;
                line-height: 1.1;
                font-weight: $f-weight-600;

            }// .title

        }// .post--list

    }// .post--archive



}// .blog










/*------------------------------------*\
    Post Type: Articoli ( post )
    single.php
\*------------------------------------*/

.single-post {



    .post--single {

        .box--title {

            .meta {

                display: flex;
                gap: 2rem 1rem;
                flex-wrap: wrap;
                margin-bottom: 2rem;

                .time {

                    font-family: $f-family-title;
                    font-weight: $f-weight-600;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    padding: 1rem;
                    background-color: var(--c-accent);

                }// .time

                .category {

                    .link {

                        display: block;
                        font-family: $f-family-title;
                        font-weight: $f-weight-600;
                        font-size: 1.4rem;
                        text-transform: uppercase;
                        padding: 1rem;
                        background-color: var(--c-accent);

                        &:hover,
                        &:focus-within {
                            background-color: var(--c-corporate-3);
                        }

                    }// .link

                }// .category

            }// .meta

        }// .box--title

        .content {

            padding: $gutter;
            padding-top: 0;

            display: flex;
            justify-content: space-between;
            gap: $gutter*2;

            @media #{$media-m} { flex-direction: column; }

            .content--text {

                width: 50%;
                max-width: 90rem;

                @media #{$media-m} { width: 100%; }

                .title3 {

                    font-size: 2.4rem;
                    line-height: 1.6;
                    font-weight: $f-weight-400;

                    @media #{$media-m} { font-size: 2rem; }

                }// .title3

                .title3 + .text { margin-top: $gutter; }

                .text {

                    font-size: 1.8rem;
                    line-height: 1.6;

                }// .text

            }// .content--text

            .content--info {

                width: 50%;
                max-width: 80rem;

                @media #{$media-m} { width: 100%; }

                & > .image {

                    .link {

                        display: block;
                        width: 100%;
                        height: 100%;

                    }// .link

                    .img {

                        display: block;

                    }// .img

                }// .image

                .image + .gallery { margin-top: $gutter; }

                .gallery {

                    display: grid;
                    grid-gap: $gutter;
                    grid-template-columns: repeat(3, 1fr);

                    @media #{$media-s} { grid-template-columns: repeat(2, 1fr); }

                    .image {

                        .link {

                            display: block;
                            width: 100%;
                            height: 100%;
                            border: .1rem solid var(--c-grey-light);

                            &:hover,
                            &:focus-within {
                                border-color: var(--c-corporate-1);
                            }

                        }// .link

                        .img {

                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;

                        }// .img

                    }// .image

                }// .gallery

                .attachments {

                    margin-top: $gutter;

                    & > .title {

                        display: inline-block;
                        font-family: $f-family-title;
                        font-weight: $f-weight-600;
                        font-size: 1.4rem;
                        text-transform: uppercase;
                        padding: 1rem;
                        background-color: var(--c-accent);

                    }// .title

                    .items {

                        margin-top: $gutter*.5;

                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        .item {

                            position: relative;

                            display: flex;
                            align-items: center;
                            gap: 1rem 2rem;

                            padding-bottom: 2rem;
                            border-bottom: .1rem solid var(--c-grey-light);

                            @media #{$media-sx} {
                                flex-direction: column;
                                align-items: flex-start;
                            }

                            .item-content {

                                .title {

                                    font-family: $f-family-title;
                                    font-weight: $f-weight-600;
                                    font-size: 1.6rem;
                                    line-height: 1.2;

                                }// .title

                            }// .item-content

                            .item-file {

                                min-width: 16rem;
                                margin-left: auto;

                                @media #{$media-sx} { margin-left: 0; }

                                .link {

                                    display: block;
                                    width: 100%;
                                    height: 100%;

                                    &:hover,
                                    &:focus-within {

                                        outline: .1rem solid var(--c-accent);
                                        outline-offset: .5rem;

                                    }

                                }

                                .info {

                                    display: flex;
                                    align-items: center;
                                    gap: 1rem;

                                    .icon {

                                        display: inline-flex;

                                        width: 2.5rem;
                                        height: 2.5rem;

                                        svg {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            fill: currentColor;
                                        }

                                    }// .icon

                                    .label {

                                        .type {

                                            font-weight: $f-weight-600;
                                            text-transform: uppercase;

                                        }// .type

                                        .size {}// .size

                                    }// .label

                                }// .info

                            }// .item-file

                        }// .item

                    }// .items

                }// .attachments

            }// .content--info

        }// .content

    }// .post--single



}// .single-post
