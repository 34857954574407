/*------------------------------------*\
    Page Template: Servizi
    servizi.php
    .page-template-servizi
\*------------------------------------*/


.page-template-servizi {

    .main {
        overflow-x: hidden;
    }

    .servizi__header {

        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 4;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter * 2 $gutter * 2;
            margin-top: $gutter * 3;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter $gutter / 2;
            margin-top: $gutter * 2.5;
        }

        .col-1 {
            width: 33.33%;

            @media #{$media-b} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            h1.title {
                font-size: 2.4rem;
                font-weight: 500;
                color: var(--c-corporate-4);
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-s} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 4;
                }
            }

            h2.title {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.4;
                color: var(--c-corporate-4);
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.6rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                    margin-bottom: $gutter / 4;
                }

                @media #{$media-sx} {
                    font-size: 2.8rem;
                }
            }

            .text {
                font-size: 2.4rem;
                font-weight: 300;
                padding-right: $gutter;

                @media #{$media-bx} {
                    font-size: 2rem;
                }

                @media #{$media-sm} {
                    font-size: 1.8rem;
                    padding-right: 0;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }//.col-1

        .col-2 {
            width: 33.33%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media #{$media-b} {
                width: 50%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            @media #{$media-sm} {
                flex-direction: column;
                padding: $gutter 0;
            }

            .forma {
                max-width: 90%;

                &.forma--uno {
                    transform: rotate(-20deg);
                }

                &.forma--due {
                    transform: rotate(80deg);
                }
            }

        }//.col-2

        .col-3 {
            width: 33.33%;
            display: flex;
            align-items: center;

            @media #{$media-b} {
                width: 50%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            .text {
                font-size: 1.8rem;
                font-weight: 300;
                padding-left: $gutter / 2;

                @media #{$media-m} {
                    padding-left: 0;
                    padding-top: $gutter;
                }

                @media #{$media-s} {
                    padding-top: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }//.col-3

    }//.servizi__header

    .servizi__section1 {
        display: flex;
        flex-wrap: wrap;
        padding: $gutter * 2 $gutter * 3;

        @media #{$media-m} {
            padding: $gutter $gutter * 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2 0;
        }

        .col-1 {
            width: 100%;

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            .title {
                font-size: 4.9rem;

                @media #{$media-m} {
                    font-size: 4rem;
                }

                @media #{$media-sm} {
                    font-size: 3.2rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }

                span {
                    color: var(--c-accent);
                }
            }

            .title::before {
                width: 60px;
                height: 60px;
                background-color: var(--c-theme-7);
                background-size: 90%;

                @media #{$media-sx} {
                    width: 50px;
                    height: 50px;
                }
            }
        }//.col-1

        .col-2 {
            width: 100%;

            .slideshow_servizi {
                display: flex;
                flex-wrap: wrap;
                margin-top: $gutter * 2;

                @media #{$media-s} {
                    margin-top: $gutter / 2;
                }

                .col-1 {
                    width: 50%;
                    padding: 0 $gutter;

                    @media #{$media-bx} {
                        padding: 0 $gutter * 2 0 $gutter;
                    }

                    @media #{$media-bx} {
                        padding: 0 $gutter * 2 0 0;
                    }

                    @media #{$media-m} {
                        width: 100%;
                    }

                    @media #{$media-s} {
                        padding: 0 $gutter / 2;
                    }

                    .labels {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: $gutter * 2;

                        @media #{$media-m} {
                            margin-bottom: $gutter;
                        }

                        @media #{$media-s} {
                            margin-bottom: $gutter / 2;
                        }

                        .title {
                            font-size: 3.2rem;
                            line-height: 1;
                            display: inline-flex;
                            padding-right: $gutter * 1.5;
                            padding-bottom: $gutter / 4;
                            margin-right: $gutter / 2;
                            cursor: pointer;
                            position: relative;

                            @media #{$media-bx} {
                                padding-right: $gutter / 2;
                            }

                            @media #{$media-b} {
                                padding-right: 0;
                            }

                            @media #{$media-m} {
                                padding-right: $gutter / 2;
                            }

                            &::before {
                                position: absolute;
                                content: '';
                                bottom: 0;
                                left: 0;
                                width: 0%;
                                height: 1rem;
                                transition: $trans-default;
                                background-color: --c-white;
                            }
                            
                            &.active, &:hover {
                                &::before {
                                    width: 100%;
                                    background-color: --c-theme-7;
                                }
                            }

                            @media #{$media-bx} {
                                font-size: 3rem;
                            }

                            @media #{$media-m} {
                                font-size: 2.8rem;
                            }

                            @media #{$media-sm} {
                                font-size: 2.4rem;
                            }

                            @media #{$media-sx} {
                                font-size: 1.8rem;
                            }
                        }
                    }//.labels

                    .content {
                        display: flex;
                        position: relative;
                        padding-right: $gutter;

                        .text {
                            position: absolute;
                            display: none;

                            @media #{$media-s} {
                                font-size: 1.4rem;
                            }

                            &.active {
                                display: block;
                                position: relative;
                                margin-bottom: $gutter / 2;
                            }

                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }//.col-1

                .col-2 {
                    width: 50%;
                    position: relative;
                    margin-top: $gutter * 2;

                    @media #{$media-m} {
                        width: 100%;
                    }

                    .galleries {
                        display: flex;
                        max-height: 515px;
                        overflow: hidden;

                        .gallery_container {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .blue_bar {
                        background-color: var(--c-corporate-1);
                        width: $gutter * 3;
                        height: 95%;
                        position: absolute;
                        bottom: 0;
                        left: - $gutter * 1.2;
                        z-index: 2;
                        display: flex;
                        justify-content: center;

                        @media #{$media-bx} {
                            width: $gutter * 2;
                        }

                        @media #{$media-m} {
                            width: $gutter * 3;
                        }

                        @media #{$media-sm} {
                            height: 8rem;
                            width: 100%;
                            bottom: auto;
                            left: 0;
                        }

                        @media #{$media-s} {
                            height: 6rem;
                            width: 100%;
                            bottom: auto;
                            left: 0;
                            top: -6rem;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            width: 80px;
                            height: 80px;
                            bottom: 1rem;
                            background: url('../images/icona_home_uno.svg') no-repeat center center;

                            @media #{$media-sm} {
                                width: 60px;
                                height: 60px;
                                left: 1rem;
                            }

                            @media #{$media-s} {
                                width: 50px;
                                height: 50px;
                                left: 1rem;
                                top: 0.25rem;
                            }
                        }
                    }

                    .forma {
                        position: absolute;

                        @media #{$media-sx} {
                            transform: scale(0.9);
                        }
                        
                        &.forma--uno { 
                            top: - $gutter * 2; 
                            right: $gutter * 5;

                            @media #{$media-s} {
                                top: - $gutter * 3; 
                            }
                        }
                        &.forma--due { 
                            top: - $gutter * 4; 
                            right: - $gutter * 2; 

                            @media #{$media-m} {
                                right: 0;
                            }

                            @media #{$media-s} {
                                top: - $gutter * 5; 
                            }
                        }
                    }
                }//.col-2

                
            }
        }//.col-2

        .col-3 {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: $gutter;

            .content__info {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: $gutter * 2;
    
                @media #{$media-s} {
                    margin-top: $gutter;
                }
    
                a {
                    margin-top: $gutter / 3;
                }
            }
    
            //.content__info
        }

    }
}

//.page-template-servizi